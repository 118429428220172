import React, { Component } from "react";
import PropTypes from "prop-types";

import {
    FormControl,
    ListGroup,
    Row, Col,
    InputGroup,
    Button,
} from "react-bootstrap";

import { settings } from "../settings";


class UserRow extends Component {

    render() {
        return <ListGroup.Item action id={this.props.id} key={this.props.id}
            onClick={() => this.props.choose(this.props.id, this.props.name)}
        >
            <Row>
                <Col md={6}>{this.props.username} </Col>
                <Col md={6}>{this.props.name}</Col>
            </Row>
        </ListGroup.Item>;
    }
}

const DEFAULT_STATE = {
    users: [],
    queryString: "",
};

// when the user stops typing for this many milliseconds, search for users
const SEARCH_DELAY = 500;

export default class UserLookup extends Component {
    timeoutRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = { ...DEFAULT_STATE };
        this.handleChange = this.handleChange.bind(this);
        this.choose = this.choose.bind(this);
        this.search = this.search.bind(this);

    }

    choose(id, name) {
        this.props.chosenCallback(id, name);
        // clear users to hide dropdown, set query string so chosen user appears
        // in the text input
        this.setState({ ...this.state, users: [], queryString: name });
    }

    handleChange(e) {
        let newQuery = e.target.value;
        this.setState({ ...this.state, queryString: newQuery });
        // trim after setting state so spaces don't get removed from the input
        newQuery = newQuery.trim();
        // we'll do the search if the user stops typing for a bit
        clearTimeout(this.timeoutRef.current);
        this.timeoutRef.current = setTimeout(() => {
            if (newQuery.length > 0)
                this.search(newQuery);
        }, SEARCH_DELAY);
    }

    search(query) {
        var url = settings.api_server + "/user/getUsers/" + query;

        fetch(url, {
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // returns a  list
                this.setState({ ...this.state, users: data });
            }.bind(this));
    }


    render() {
        var userData = this.state.users.map(x => <UserRow
            key={x.user_id}
            id={x.user_id}
            username={x.username}
            name={x.firstname + " " + x.lastname}
            choose={this.choose} />);
        return <div>
            <InputGroup>
                <FormControl
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={this.state.queryString}
                    placeholder="Enter name or email"
                />
                {this.props.clearButton && <InputGroup.Append>
                    <Button size="sm" variant="outline-danger"
                        onClick={() => this.choose(null, "")}
                    >
                        Clear
                    </Button>
                </InputGroup.Append>}
            </InputGroup>
            <ListGroup>
                {userData}
            </ListGroup>
        </div>;

    }
}

UserLookup.propTypes = {
    chosenCallback: PropTypes.func.isRequired,
    clearButton: PropTypes.bool, // optional, if true a clear button is rendered
};
