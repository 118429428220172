import React from "react";

import {
    Form,
    Button,
    Row,
    Col,
} from "react-bootstrap";

/**
 * Returns a FormGroup rendered as a Row, for creating a translation attribute.
 * @param {Object} props
 * @param {(name: string, value: string) => void} props.handleChange callback
 * for when the name or value of the new attribute is changed.
 * @param {(name: string, value: string)} props.handleAdd callback to add a new
 * attribute (this component will ensure neither field is empty).
 */
export default function CreateAttribute({
    handleChange,
    handleAdd,
})
{
    const [attrName, setAttrName] = React.useState("");
    const [attrVal, setAttrVal] = React.useState("");
    const nameRef = React.useRef(null);
    const valRef = React.useRef(null);

    const changeName = React.useCallback(e => {
        setAttrName(e.target.value);
        handleChange(e.target.value, attrVal);
    }, [handleChange]);

    const changeVal = React.useCallback(e => {
        setAttrVal(e.target.value);
        handleChange(attrName, e.target.value);
    }, [handleChange]);

    const onAdd = React.useCallback(() => {
        if (attrName !== "" && attrVal !== "")
        {
            handleAdd(attrName, attrVal);
            handleChange("", "");
            setAttrName("");
            setAttrVal("");
            if (nameRef.current)
                nameRef.current.focus();
        }
        else
        {
            alert("Please enter values before adding");
        }
    }, [handleAdd, handleChange]);

    // Pressing enter on the name advances to the value field
    const nameKeyDown = React.useCallback(e => {
        if (e.key === "Enter" && valRef.current)
            valRef.current.focus();
    }, []);

    // Pressing enter on the value is the same as clicking "Add"
    const valKeyDown = React.useCallback(e => {
        if (e.key === "Enter")
            onAdd();
    }, [onAdd]);

    return <Form.Group className="attribute-row" as={Row}>
        <Col sm={3}>
            <Form.Control type="textarea"
                value={attrName}
                onChange={changeName}
                ref={nameRef}
                onKeyDown={nameKeyDown}
            />
        </Col>
        <Col sm={8}>
            <Form.Control type="textarea"
                value={attrVal}
                onChange={changeVal}
                ref={valRef}
                onKeyDown={valKeyDown}
            />
        </Col>
        <Col sm={1}>
            <Button variant="success" size="sm" onClick={onAdd}>
                Add
            </Button>
        </Col>
    </Form.Group>;
}
