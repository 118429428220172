import React, { Component } from "react";
import "./App.css";
import { connect } from "react-redux";

import {
    Container, Row, Col,
    Card
} from "react-bootstrap";

import { setAuctionCarDetail, getAuctionCarData, clearAuctionData } from "./actions/auction_car_actions";
import PCANav from "./dashboard-components/navbar";

import AdminActionCard from "./auction_car_views/sidebar_views/AdminActionCard";
import AuctionCarUserStatus from "./auction_car_views/AuctionCarUserStatus";
import AuctionCarImages from "./auction_car_views/AuctionCarImages";
import AuctionCarDetails from "./auction_car_views/AuctionCarDetails";
import Translation from "./auction_car_views/Tranlsation";
import QuestionModal from "./auction_car_views/ask_question_modal";
import Questions from "./auction_car_views/questions";
import PreviousAuctions from "./auction_car_views/PreviousAuctions";
import ProductionData from "./dashboard-components/productionData";
import ProbablyAuctionSheet from "./widgets/ProbablyAuctionSheet";

import { Button } from "react-bootstrap";

class AuctionCar extends Component {
    componentDidMount () {
        // Note: translations, image requests, bids and questions data are all
        // fetched by their sidebar buttons (components in AuctionCarUserStatus)
        getAuctionCarData(this.props.match.params.auction_id);
    }

    componentWillUnmount () {
        this.props.clearAuctionData();
    }

    render () {
        return (
            <div>
                <PCANav />
                <Container>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header>
                                    Car Images
                                    <Button size="sm" style={{float: "right"}}
                                        href={"#/print_auction_car/" + this.props.match.params.auction_id}
                                        target="_blank" rel="noreferrer"
                                    >
                                        Print Auction Details
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <AuctionCarImages />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {this.props.translation_loaded && this.props.requested_translation && this.props.translation_completed &&
                        <Row>
                            <Col sm={12} md={6}>
                                <Translation />
                            </Col>
                            <Col className="alignColVertical" sm={12} md={6}>
                                <ProbablyAuctionSheet />
                            </Col>
                        </Row>
                    }
                    <Questions auction_id={this.props.match.params.auction_id} />
                    <Row>
                        <Col md={8}>

                            <Card>
                                <Card.Header >Look up more production data</Card.Header>
                                <Card.Body className="flush_card">
                                    <Container>
                                        Enter the Make whole chassis number of this vehicle
                                        <ProductionData data={this.props.data} />
                                    </Container>
                                </Card.Body>
                            </Card>

                            <Card>
                                <Card.Header>Car Details</Card.Header>
                                <AuctionCarDetails id={this.props.match.params.auction_id} />
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card>
                                <Card.Header>Actions</Card.Header>
                                <AuctionCarUserStatus id={this.props.match.params.auction_id} />
                            </Card>
                            <AdminActionCard />
                            {
                                <Card>
                                    <Card.Header >Previous sales of this vehicle</Card.Header>
                                    <Card.Body className="flush_card">
                                        <PreviousAuctions />
                                    </Card.Body>
                                </Card>
                            }
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} md={12}>
                            <QuestionModal auction_id={this.props.match.params.auction_id} />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.auction_car.data,
        loading: state.auction_car.loading,

        translation_loaded: state.auction_car.translation_loaded,
        requested_translation: state.auction_car.requested_translation,
        translation_completed: state.auction_car.translation_completed

    };
};

const mapDispatchToProps = dispatch => ({
    setAuctionCarDetail: (car_data) => dispatch(setAuctionCarDetail(car_data)),
    clearAuctionData: () => dispatch(clearAuctionData())
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionCar);
