import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../App.css";
import { connect } from "react-redux";

import {
    Button
} from "react-bootstrap";

import {
    setBidData
} from "../../actions/auction_car_actions";

import { settings } from "../../settings";

import commaNumber from "../../widgets/commaNumbers";

import CreateBidModal from "../bid_modals/create_bid_modal";
import CreateBidConfirmModal from "../bid_modals/create_bid_confirm_modal";
import UpdateBidModal from "../bid_modals/update_bid_modal";
import UpdateBidConfirmModal from "../bid_modals/update_bid_confirm_modal";
import CancelBidConfirmModal from "../bid_modals/cancel_bid_confirm_modal";

import { showCreate, showUpdate } from "../../actions/user_bid_modal_actions";

class Bid extends Component {
    constructor() {
        super();
        this.fetchBid = this.fetchBid.bind(this);
    }

    fetchBid() {
        const url = settings.api_server + "/bids/bid/" + this.props.id;

        fetch(url, {
            credentials: "include",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                // push to redux
                // data should be a car data
                this.props.setBidData(data.has_bid, data.amount, data.cancelled, data.bidding_possible, data.message, data.comment);
            }.bind(this));
    }

    componentDidMount() {
        this.fetchBid();
    }

    render() {
        let bidText = null;
        let bidButton = null;
        let bidInfo = null;
        if (!this.props.loaded) {
            bidText = <p>Loading bid info...</p>;
        }
        else if (this.props.bids_available === 0) {
            bidText = <p>No bids available, can not bid.</p>;
        }
        else if (!this.props.can_bid) {
            bidText = <p>Insufficient deposit to bid</p>;
        }
        else if (this.props.bidMessage !== null) {
            bidText = <p>{this.props.bidMessage}</p>;
        }

        // Disable buttons for making bids if we're waiting on the auction data
        // or weren't able to fetch it.
        const auctionNotReady = this.props.auction_loading
            || this.props.auction_failure;

        // be sure to leave this info
        if (this.props.has_bid) {
            if (this.props.bid_cancelled) {
                bidInfo = <p>Bid cancelled</p>;
            }
            else {
                bidInfo = <p>Bid set for {commaNumber(this.props.amount)} JPY</p>;
            }
            if (this.props.carIsBiddable) {
                bidButton = <p>
                    <Button block onClick={this.props.showUpdate}
                        disabled={auctionNotReady}
                    >
                        Update bid
                    </Button>
                </p>;
            }
        }
        else if (this.props.carIsBiddable) {
            // inform how many are left, if they are limited.
            bidButton = <p>
                <Button block onClick={this.props.showCreate}
                    disabled={auctionNotReady}
                >
                    Bid on this car{this.props.bids_available > 0 && ": " + this.props.bids_available + " left"}
                </Button>
            </p>;
        }

        // Clear button if biddable time has past, and other reasons bidding can not occur
        if (!this.props.can_bid) {
            bidButton = null;
        }

        return <div>
            <CreateBidModal id={this.props.id} />
            <CreateBidConfirmModal id={this.props.id} fetchBid={this.fetchBid} />
            <UpdateBidModal />
            <UpdateBidConfirmModal id={this.props.id} fetchBid={this.fetchBid} />
            <CancelBidConfirmModal id={this.props.id} fetchBid={this.fetchBid} />
            {bidInfo}
            {bidText}
            {bidButton}

        </div>;
    }
}

Bid.propTypes = {
    id: PropTypes.string.isRequired,
};

const bidState = state => {
    return {
        loaded: state.auction_car.bid_loaded,
        has_bid: state.auction_car.has_bid,
        bid_cancelled: state.auction_car.is_cancelled,
        amount: state.auction_car.bid_amount,
        carIsBiddable: state.auction_car.carIsBiddable,
        bidMessage: state.auction_car.bidMessage,
        can_bid: state.profile.can_bid,
        bids_available: state.profile.bids_available,
        balance: state.profile.balance,

        // state of auction data fetch, disable bid buttons if it's still
        // loading or failed to fetch
        auction_loading: state.auction_car.loading,
        auction_failure: state.auction_car.failure,

        fxLoaded: state.fx.loading, // fx data
        fx_data: state.fx.fx_data
    };
};

const bidDispatch = dispatch => ({
    setBidData: (has_bid, amount, can, canBid, bidMessage, comm) => dispatch(setBidData(has_bid, amount, can, canBid, bidMessage, comm)),
    showCreate: () => dispatch(showCreate()),
    showUpdate: () => dispatch(showUpdate())
});
export default connect(bidState, bidDispatch)(Bid);
