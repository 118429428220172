import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Button,
    Modal,
    FormControl,
    FormLabel,
    Alert,
    Row,
    Col,
} from "react-bootstrap";

import { settings } from "../../../settings";
import { setMessage } from "../../../actions/alert_actions";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import UserLookupTool from "../../../admin_views/UserLookupTool";

import MoneyInput from "../../../widgets/MoneyInput";
import FxView from "../../../widgets/FxView";

const DEFAULT_STATE = {
    chosenUser: null,
    chosenID: null,
    showModal: false,
    amount: "",
    comment: "",
    message: null
};

class RequestBid extends Component {
    

    constructor() {
        super();
        this.state = { ...DEFAULT_STATE };
        this.save = this.save.bind(this);
        this.chosen = this.chosen.bind(this);
        this.updateAmount = this.updateAmount.bind(this);
    }

    chosen(id, name) {
        this.setState({ ...this.state, chosenID: id, chosenName: name, users: [] });
    }

    updateAmount(e) {
        this.setState({...this.state, amount: e});
    }

    save() {
        // send the request
        // close the modal
        var url = settings.api_server + "/bids/bid/" + this.props.car_data.vehicle_id + "/" + this.state.chosenID;

        var postdata = {
            amount: this.state.amount,
            comment: this.state.comment,
        };
        fetch(url, {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    throw new Error("Bad response from server");
                }
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    // show the banner
                    this.props.setMessage("Request made for user");
                    // go away
                    this.setState({ ...DEFAULT_STATE });
                }
                else {
                    // show the message locally
                    // go away
                    this.setState({ ...this.state, message: data.message });
                }

            }.bind(this));

    }

    render() {
        if (!this.props.isAdmin)
            return null;


        // Grey out the button if we're still loading the auction data, or it
        // wasn't fetched
        return <div>
            <Button block
                onClick={() => this.setState({ ...this.state, showModal: true })}
                disabled={this.props.loading || this.props.failure}
            >
                Create bid for user
            </Button>
            <Modal size="lg" show={this.state.showModal} onHide={() => this.setState({ ...DEFAULT_STATE })}>
                <ModalHeader>
                    <Modal.Title>Request bid for User</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    Choose user to bid translation for:
                    <UserLookupTool chosenCallback={this.chosen} />
                    {this.state.chosenID === null ?
                        <p>Chosen: None</p> :
                        <p>Chosen: {this.state.chosenName}</p>
                    }
                    <Row className="alignCentre">
                        <FormLabel column sm={2} md={2}>Amount JPY</FormLabel>
                        <Col sm={4} md={4} lg={3}>
                            <MoneyInput
                                className="rightAlignTextbox"
                                placeholder="Amount"
                                value={this.state.amount}
                                onChange={this.updateAmount}
                                thousands
                            />
                        </Col>
                        <Col sm={6} md={6} lg={7}>
                            {this.state.amount > 0 && <span>Approximately <FxView amount={this.state.amount} thousands/></span>}
                        </Col>
                    </Row>
                    <p>Comment</p>
                    <FormControl
                        as="textarea"
                        onChange={(e) => this.setState({ ...this.state, comment: e.target.value })}
                        value={this.state.comment}
                    />
                    {this.state.message !== null && <Alert variant="danger">{this.state.message}</Alert>}
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={this.save} disabled={this.state.chosenID === null || this.state.amount.length === 0}>
                        Do Request
                    </Button>
                    <Button variant="secondary" onClick={() => this.setState({ ...DEFAULT_STATE })}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>;

    }
}


const stateToProps = state => {
    return {
        isAdmin: state.profile.is_admin,

        car_data: state.auction_car.data,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure,
    };
};

const dispatches = dispatch => ({
    setMessage: (m) => dispatch(setMessage(m))
});

export default connect(stateToProps, dispatches)(RequestBid);
