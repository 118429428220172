
import { settings } from "../settings";

const search_defaultState = {
    total_auction: "loading",
    makes: [],
    make_model: [],
    make_selected: settings.make_placeholder,
    models_selected: [],
    model_id_selected: [],
    start_year: "",
    end_year: "",
    fetching: false,
    results: null,
    min_grade: "",
    odo_min: "",
    odo_max: "",
    chassis: "",
    engine_min: "",
    engine_max: "",
    trans: "",
    gears: null,
    auction: "",
    auctionNumber: "",
    start_date: "",
    end_data: "",
    sortColumn: -4,
    page: 0,
    auctionHouseList: [],
    auctionHouseLoaded: false,
    auctionHouseLastUpdate: 0,
    showAuctionHouses: false,
    showSearch: true,
    baseinfoLastUpdate: 0,
    numericCount: -1,
    ac: false,
    aw: false,
    le: false,
    sr: false,
    pw: false,
    ps: false,
    tv: false,
    camper: false,
    dump: false,
    crane: false,
    doubleCab: false,
    loader: false,
    bus: false,
    grade_star: false,
    grade_two: false,
    grade_three: false,
    grade_three_five: false,
    grade_four: false,
    grade_four_five: false,
    grade_five: false,
    grade_R: false,
    keyword: "",
    drivetrain: settings.drivetrain_placeholder,
    // note: sale prices are multiples of 1000 JPY
    sold_price_min: "",
    sold_price_max: "",
};

const search = (state = search_defaultState, action) => {
    let i = 0;
    switch (action.type) {
    case "BASE_INFO":

        // make a list of makes, then keep the list of everything
        var makes = [];
        // var total = 0;
        for (i = 0; i < action.data.length; i++) {
        // is this model already in the list
            let inList = false;
            for (let k = 0; k < makes.length && !inList; k++) {
                if (makes[k].make === action.data[i].make) {
                    inList = true;
                    // add the amount
                    // makes[k].count += parseInt(action.data[i].TAG3, 10);
                }
            }
            if (!inList) {
                makes.push({ make: action.data[i].make });
            }
        // total += parseInt(action.data[i].TAG3, 10);
        }

        return {
            ...state,
            makes,
            make_model: action.data,
            baseinfoLastUpdate: (new Date()).getTime()
        // total_auction: total,
        };
    case "SET_FETCHING":
        return {
            ...state,
            fetching: true,
            showSearch: false
        };
    case "FETCH_COMPLETE":
        return {
            ...state,
            fetching: false
        };
    case "SELECT_MAKE":
        return {
            ...state,
            make_selected: action.make_name,
            models_selected: search_defaultState.models_selected
        };
    case "CLEAR_SELECTION":
        // total reset
        // keep the auction list, setting them all to not selected
        // keep the list of make models
        for (i = 0; i < state.auctionHouseList.length; i++) {
            state.auctionHouseList[i].selected = false;
        }
        return {
            ...search_defaultState,
            makes: state.makes, // don't clear the base info
            make_model: state.make_model,
            auctionHouseList: state.auctionHouseList
        };
    case "SELECT_MODELS":
        return {
            ...state,
            models_selected: action.models,
            model_id_selected: action.ids

        };
    case "SET_START_YEAR":
        return {
            ...state,
            start_year: action.start_year
        };
    case "SET_END_YEAR":
        return {
            ...state,
            end_year: action.end_year
        };
    case "SET_AUCTION_NUMBER":
        // only accept numbers
        if (isNaN(action.auctionNumber)) { return { ...state }; }
        return {
            ...state,
            auctionNumber: action.auctionNumber
        };

    case "SET_ODO_MIN":
        return {
            ...state,
            odo_min: action.odo_min
        };
    case "SET_ODO_MAX":
        return {
            ...state,
            odo_max: action.odo_max
        };
    case "SET_CHASSIS":
        return {
            ...state,
            chassis: action.chassis
        };
    case "SET_ENGINE_MIN":
        return {
            ...state,
            engine_min: action.engine_min
        };
    case "SET_ENGINE_MAX":
        return {
            ...state,
            engine_max: action.engine_max
        };
    case "SET_TRANS":
        return {
            ...state,
            trans: action.trans
        };
    case "SELECT_GEARS":
        return {
            ...state,
            gears: action.gears
        };
    case "SET_AUCTION":
        return {
            ...state,
            auction: action.auction
        };
    case "SET_START_DATE":
        return {
            ...state,
            start_date: action.start_date
        };
    case "SET_END_DATE":
        return {
            ...state,
            end_date: action.end_date
        };

    case "SET_KEYWORD":
        return {
            ...state,
            keyword: action.keyword
        };
    case "SET_DRIVETRAIN":
        return {
            ...state,
            drivetrain: action.drivetrain
        };
    case "SET_SOLD_PRICE_MIN":
        // note: sale prices are multiples of 1000 JPY
        return {
            ...state,
            sold_price_min: action.sold_price_min
        };
    case "SET_SOLD_PRICE_MAX":
        // note: sale prices are multiples of 1000 JPY
        return {
            ...state,
            sold_price_max: action.sold_price_max
        };
    case "NEXT_PAGE":
        return {
            ...state,
            page: state.page + 1
        };
    case "PREV_PAGE":
        return {
            ...state,
            page: state.page - 1
        };
    case "SET_PAGE":
        return {
            ...state,
            page: 0, // pages are 0-indexed
        };
    case "SET_SORT_COLUMN":
        return {
            ...state,
            sortColumn: action.sortColumn
        };
    case "SET_NUMERIC_COUNT":
        return {
            ...state,
            numericCount: action.count
        };
    case "TOGGLE_SHOW_SEARCH":
        return {
            ...state,
            showSearch: !state.showSearch
        };
    case "SET_AUCTION_HOUSE_LIST":
        return {
            ...state,
            auctionHouseList: action.auctionHouseList,
            auctionHouseLoaded: true
        };
    case "TOGGLE_AUCTION_HOUSES":
        return {
            ...state,
            showAuctionHouses: !state.showAuctionHouses
        };
    case "TOGGLE_AUCTIONS":
        /* find the auction house id, toggle the active status. */
        /* eslint-disable no-inner-declarations, no-case-declarations */
        function toggleInList (auc) {
        // is this in the list?
            if (action.auctions.indexOf(auc.auction_name) !== -1) {
                if ("turnOn" in action) { auc.selected = action.turnOn; } else { auc.selected = !auc.selected; }
            }
            return auc;
        }
        /* eslint-enable no-inner-declarations, no-case-declarations */
        var newAucList = state.auctionHouseList.map(toggleInList);

        return {
            ...state,
            auctionHouseList: newAucList,
            auctionHouseLastUpdate: (new Date()).getTime()
        };
    case "SET_SEARCH_FROM_DICT":
        // generally from a saved search
        // rename fields that might be different
        var grades = {};
        if ("makeStr" in action.theDict) { action.theDict.make_selected = action.theDict.makeStr; }
        if ("models" in action.theDict) { action.theDict.models_selected = action.theDict.models; }
        if ("model_ids" in action.theDict) { action.theDict.model_id_selected = action.theDict.model_ids; }
        // grades are in a list. Unlist them
        if ("grades" in action.theDict) {
            grades.grade_star = action.theDict.grades.indexOf("***") >= 0;
            grades.grade_two = action.theDict.grades.indexOf("2") >= 0;
            grades.grade_three = action.theDict.grades.indexOf("3") >= 0;
            grades.grade_three_five = action.theDict.grades.indexOf("3.5") >= 0;
            grades.grade_four = action.theDict.grades.indexOf("4") >= 0;
            grades.grade_four_five = action.theDict.grades.indexOf("4.5") >= 0;
            grades.grade_five = action.theDict.grades.indexOf("5") >= 0;
            grades.grade_R = action.theDict.grades.indexOf("R") >= 0;
        }
        return {
            ...search_defaultState, // first clear the search state
            ...action.theDict, // then override with any provided info
            ...grades, // load the grades
            makes: state.makes, // don't clear the base info
            make_model: state.make_model
        };
    case "TOGGLE_AC":
        return {
            ...state,
            ac: !state.ac
        };
    case "TOGGLE_AW":
        return {
            ...state,
            aw: !state.aw
        };
    case "TOGGLE_LE":
        return {
            ...state,
            le: !state.le
        };
    case "TOGGLE_SR":
        return {
            ...state,
            sr: !state.sr
        };
    case "TOGGLE_PW":
        return {
            ...state,
            pw: !state.pw
        };
    case "TOGGLE_PS":
        return {
            ...state,
            ps: !state.ps
        };
    case "TOGGLE_TV":
        return {
            ...state,
            tv: !state.tv
        };
    case "TOGGLE_CAMPER":
        return {
            ...state,
            camper: !state.camper
        };
    case "TOGGLE_DUMP":
        return {
            ...state,
            dump: !state.dump
        };
    case "TOGGLE_CRANE":
        return {
            ...state,
            crane: !state.crane
        };
    case "TOGGLE_DOUBLE_CAB":
        return {
            ...state,
            doubleCab: !state.doubleCab
        };
    case "TOGGLE_LOADER":
        return {
            ...state,
            loader: !state.loader
        };
    case "TOGGLE_BUS":
        return {
            ...state,
            bus: !state.bus
        };

    case "TOGGLE_GRADE_STAR":
        return {
            ...state,
            grade_star: !state.grade_star
        };
    case "TOGGLE_GRADE_2":
        return {
            ...state,
            grade_two: !state.grade_two
        };
    case "TOGGLE_GRADE_3":
        return {
            ...state,
            grade_three: !state.grade_three
        };
    case "TOGGLE_GRADE_THREE_3_5":
        return {
            ...state,
            grade_three_five: !state.grade_three_five
        };
    case "TOGGLE_GRADE_4":
        return {
            ...state,
            grade_four: !state.grade_four
        };
    case "TOGGLE_GRADE_4_5":
        return {
            ...state,
            grade_four_five: !state.grade_four_five
        };
    case "TOGGLE_GRADE_5":
        return {
            ...state,
            grade_five: !state.grade_five
        };
    case "TOGGLE_GRADE_R":
        return {
            ...state,
            grade_R: !state.grade_R
        };
    default:
        return state;
    }
};

export default search;
