import React from "react";
import { settings } from "../settings";

import {
    Accordion,
    Button,
    Card,
    Modal,
} from "react-bootstrap";

import Octicon, {
    Alert as AlertIcon,
} from "@githubprimer/octicons-react";

import CustomAccordionToggle from "../admin_views/user_admin_views/CustomAccordionToggle";
import LoadingError from "../widgets/LoadingError";

/**
 * Returns a modal (and the button to open it) for selecting a quiz to assign to
 * the given purchase.
 * @param {Object} props
 * @param {string} props.purchase_id
 * @param {Object | null} props.pendingQuiz the quiz already assigned to the
 * purchase, if any. Needed to warn users that assigning another quiz will
 * overwrite the existing one.
 * @param {() => void} props.updateCallback callback to re-fetch the current
 * quiz after one is assigned.
 */
export default function CarAssignQuiz({
    purchase_id,
    pendingQuiz,
    updateCallback,
})
{
    const [showModal, setShowModal] = React.useState(false);
    const [quizTemplates, setQuizTemplates] = React.useState([]);
    const [templatesError, setTemplatesError] = React.useState(null);

    // when first mounted, fetch the quiz templates
    React.useEffect(() => {
        const url = settings.api_server + "/quiz/admin/all";
        fetch(url, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                // endpoint doesn't return an object with a success flag, just
                // the list of quiz templates
                templates => {
                    setQuizTemplates(templates);
                    setTemplatesError(null);
                },
                reason => {
                    setQuizTemplates([]);
                    setTemplatesError(reason);
                }
            );
    }, []);

    // call the updateCallback upon exiting the modal, that way when one of the
    // buttons for assigning a quiz closes the modal, the assigned quiz will be
    // updated
    return <>
        <Button size="sm" variant="outline-primary"
            onClick={() => setShowModal(true)}
        >
            Assign
        </Button>
        <Modal show={showModal} size="lg"
            onHide={() => setShowModal(false)}
            onExit={updateCallback}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">
                    Assign Quiz
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: "0px"}}>
                {pendingQuiz && <div style={{padding: "5px 20px"}}>
                    <Octicon icon={AlertIcon}/> Assigning a new quiz will
                    replace the one already assigned to this purchase (<i>
                        {pendingQuiz.quiz_name}
                    </i>).
                </div>}
                {templatesError && <LoadingError message={
                    `Error loading quiz templates: ${templatesError}`
                }/>}
                <QuizTemplateList
                    purchase_id={purchase_id}
                    quizTemplates={quizTemplates}
                    setShowModal={setShowModal}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary"
                    onClick={() => setShowModal(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>;
}

/**
 * Renders a list of quiz templates, and buttons to assign them to the purchase.
 * When an assign button is clicked, it closes the modal it belongs to.
 * @param {Object} props
 * @param {string} props.purchase_id
 * @param {Object[]} props.quizTemplates
 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setShowModal
 */
function QuizTemplateList({
    purchase_id,
    quizTemplates,
    setShowModal,
})
{
    const [assignmentError, setAssignmentError] = React.useState(null);

    const assignQuiz = React.useCallback((quiz_id) => {
        const url = `${settings.api_server}/quiz/admin/${purchase_id}/${quiz_id}`;
        fetch(url, {
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(data => data.success ?
                Promise.resolve()
                : Promise.reject(data.message)
            )
            .then(
                () => {
                    setShowModal(false);
                    setAssignmentError(null);
                },
                reason => {
                    // don't hide the modal, show the error in it
                    setAssignmentError(reason);
                }
            );
    }, [purchase_id, setShowModal]);

    // List of quiz templates is an accordion, each item can be expanded to show
    // the questions in the quiz
    return <Accordion defaultActiveKey="0">
        {quizTemplates.map(template => <Card key={template.quiz_id}>
            <Card.Header>
                <CustomAccordionToggle eventKey={template.quiz_id} iconBefore>
                    {template.quiz_name} ({template.questions.length} questions)
                    <Button style={{display: "inline-block", float: "right"}}
                        onClick={() => assignQuiz(template.quiz_id)}
                    >
                        Assign
                    </Button>
                </CustomAccordionToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={template.quiz_id}>
                <Card.Body>
                    <ol>
                        {template.questions.map(q => <li key={q.question_id}>
                            {q.question_text}
                        </li>)}
                    </ol>
                </Card.Body>
            </Accordion.Collapse>
        </Card>)}
        {assignmentError && <LoadingError message={
            `Error assigning quiz: ${assignmentError}`
        }/>}
    </Accordion>;
}
