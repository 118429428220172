import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Alert,
    FormControl,
    Modal,
    Form,
    Button,
    Container, Row, Col,
    Collapse
} from "react-bootstrap";

import { settings } from "../../settings";
import { translations_defaultState } from "../../reducers/translations";

import { getAuctionCarData, clearAuctionData } from "../../actions/auction_car_actions";
import {
    unshowTranslation,
    changeMain,
    changeMainAdd,
    changeMainDelete,
    changeSalesPoints,
    changeNotes,
    changeReport,
    changeDiagramNotes,
    changeExporterNotes,
    setAllTranslationData,
    toggleGiveBackRequest,
    setLockOwnership
} from "../../actions/translation_actions";
import AuctionCarImages from "../../auction_car_views/AuctionCarImages";
import AdminOnly from "../../dashboard-components/AdminOnly";
import CreateAttribute from "./CreateAttribute.js";
import EditAttribute from "./EditAttribute.js";
import {
    translateMakeF,
    translateModelF
} from "../../functions";

// this component will fetch the requested images it needs
import ImageList from "../view_requested_car_images";
import RequestedImageUploadByID from "./requested_image_upload_by_id";
import ProductionData from "../../dashboard-components/productionData";
// temporarily removed, broken by Aleado
// import RequestQuickImages from "../../auction_car_views/sidebar_views/RequestQuickImages";

import "./translation_modal.css";

class Person extends Component {
    render (){
        let manager = <b>Account manager required</b>;
        let r = this.props.who;
        if (r.acct_manager !== null){
            manager = r.acct_manager_name;
        }
        return <span>
            {r.firstname} {r.lastname + " " /* force a space before the '(' */}
            (translation requests: {r.translation_requests_available},
            bids: {r.bids_available}, account manager: {manager})
        </span>;
    }
}

class TranslationModal extends Component {
    constructor() {
        super();
        this.handleClose = this.handleClose.bind(this);
        this.saveAndClose = this.saveAndClose.bind(this);
        this.save = this.save.bind(this);
        this.ignore = this.ignore.bind(this);
        this.loadData = this.loadData.bind(this);
        this.changeReason = this.changeReason.bind(this);
        this.ignoreViewClick = this.ignoreViewClick.bind(this);
        this.updateLock = this.updateLock.bind(this);
        this.mainInfo = this.mainInfo.bind(this);

        this.default_state = { loaded: false, pushing_data: false, errorMessage: null, showIgnore: false, ignore_reason: "", newAttr: "", newVal: "", requesters: [] };
        this.state = { ...this.default_state };
        this.input = "";

        this.carInfoDict = null; // stores the car info parsed from the data service.
    }

    changeReason(e) {
        this.setState({ ...this.state, ignore_reason: e.target.value });
    }

    ignoreViewClick() {
        this.setState({ ...this.state, showIgnore: !this.state.showIgnore });
    }

    ignore() {
        this.setState({
            ...this.state,
            pushing_data: true
        });

        const url = settings.api_server + "/translations/ignore/" + this.props.show_translation_id;
        const postdata = {
            reason: this.state.ignore_reason,
            give_request_back: this.props.give_request_back
        };
        fetch(url, {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify(postdata),
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Ignore- Problem contacting server" });
                    throw new Error("Bad response from server");
                } else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState(this.default_state);
                    this.handleClose();
                    // refresh underlying list
                    if (this.props.updateCallback) { this.props.updateCallback(); }
                } else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: data.message });
                }
            }.bind(this));
    }

    saveAndClose() {
        this.setState({ ...this.state, pushing_data: true });
        if (this.state.newAttr !== "" && this.state.newVal !== "") {
            this.props.changeMainAdd(this.state.newAttr, this.state.newVal);
        }
        const postdata = {
            main: JSON.stringify(this.props.main),
            report: this.props.report,
            notes: this.props.notes,
            sales_points: this.props.sales_points,
            diagram_notes: this.props.diagram_notes,
            exporter_notes: this.props.exporter_notes,
            give_request_back: this.props.give_request_back
        };

        const url = settings.api_server + "/translations/queue/" + this.props.show_translation_id;
        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "POST",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Save and close - Problem contacting server" });
                    throw new Error("Bad response from server");
                } else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.handleClose();
                    // refresh underlying list
                    if (this.props.updateCallback) {
                        this.props.updateCallback();
                    }
                    this.setState(this.default_state);
                } else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Could not save translation" });
                }
            }.bind(this));
    }

    save() {
        /*
            Just save, don't exit, don't notify
            */
        this.setState({ ...this.state, pushing_data: true });
        if (this.state.newAttr !== "" && this.state.newVal !== "") {
            this.props.changeMainAdd(this.state.newAttr, this.state.newVal);
        }
        const postdata = {
            main: JSON.stringify(this.props.main),
            report: this.props.report,
            notes: this.props.notes,
            sales_points: this.props.sales_points,
            diagram_notes: this.props.diagram_notes,
            exporter_notes: this.props.exporter_notes,
            give_request_back: this.props.give_request_back
        };

        const url = settings.api_server + "/translations/queue/" + this.props.show_translation_id;
        fetch(url, {
            body: JSON.stringify(postdata),
            credentials: "include",
            method: "PUT",
            headers: {
                "content-type": "application/json"
            }
        })
            .then(function (response) {
                if (response.status >= 400) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Save and close - Problem contacting server" });
                    throw new Error("Bad response from server");
                } else {
                    return response.json();
                }
            }.bind(this))
            .then(function (data) {
                if (data.success) {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: null });
                    this.handleClose();
                } else {
                    this.setState({ ...this.state, pushing_data: false, errorMessage: "Could not save translation" });
                }
            }.bind(this));
    }

    loadData() {
        // load this translation's data
        // load everything, even if we have some data
        // this component is for add, or edit
        if (this.props.show_translation_id > -1) {
            // get this car's auction data
            getAuctionCarData(this.props.show_vehicle_id);

            const url = settings.api_server + "/translations/queue/" + this.props.show_translation_id;

            fetch(url, {
                credentials: "include",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        this.setState({ ...this.state, pushing_data: false, errorMessage: "Error " + response.status + " Problem contacting server with transaction data" });
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    const newState = this.state;
                    newState.translation = data.translation;
                    newState.requesters = data.requesters;
                    newState.loaded = true;
                    this.input = "loaded";

                    // check which data to load.
                    // if main is none, or empty string, and we have car data... load that.
                    if ((data.main === null || data.main === "") && this.carInfoDict !== null) {
                        this.props.setAllTranslationData(this.carInfoDict, data.description, data.sales_points, data.notes, data.report, data.diagram_notes, data.exporter_notes, data.translation_completed);
                    } else {
                        this.props.setAllTranslationData(JSON.parse(data.main), data.description, data.sales_points, data.notes, data.report, data.diagram_notes, data.exporter_notes, data.translation_completed);
                    }
                    this.setState(newState);
                }.bind(this));

            // check for locks
            this.updateLock();
            this.lockUpdater = setInterval(this.updateLock, 20000);
        }
    }

    componentDidUpdate(oldProps) {
        // car data loaded
        if (this.props.car_data_loading !== oldProps.car_data_loading && !this.props.car_data_loading && !this.props.failure) {
            // if the translation is currently an empty string,
            // fill it with stuff

            let infoVals = Object.values(this.props.car_data.info);
            // now strip them all
            infoVals = infoVals.map(x => x.trim());
            const infoStr = infoVals.join(", ");
            this.carInfoDict = {
                ...translations_defaultState.main,
                Auction: this.props.car_data.auction_house ? this.props.car_data.auction_house : null,
                Lot: this.props.car_data.lot,
                Year: this.props.car_data.year,
                Make: translateMakeF(this.props.car_data.make),
                Model: translateModelF(this.props.car_data.model),
                Displacement: this.props.car_data.displacement,
                Grade: this.props.car_data.grade,
                Odometer: this.props.car_data.mileage,
                Trim: this.props.car_data.trim,
                Colour: this.props.car_data.colour,
                Options: infoStr
            };

            /* if (this.props.car_data.KPP_TYPE === "2") {
                this.carInfoDict["Transmission"] = "Auto";
            }
            else {
                var gears = this.props.car_data.KPP.slice(this.props.car_data.KPP.length - 1);
                this.carInfoDict["Transmission"] = gears;
            } */
            this.carInfoDict.Transmission = this.props.car_data.transmission;

            // do we have an info string?
            if (" interior " in this.props.car_data.info) {
                this.carInfoDict["Interior Grade"] = this.props.car_data.info[" interior "];
            }

            // set the data if translation data is loaded. If not, we cache it, and load it when the translation
            // data does load
            if (!this.props.car_data_loading && (this.props.main === "" || this.props.main === null || this.props.main === undefined)) {
                this.props.changeMain(this.carInfoDict);
            }
        }
    }

    updateLock() {
        // check if we own the lock
        const url = settings.api_server + "/translations/lock/" + this.props.show_translation_id;
        if (this.props.show_translation_id > -1) {
            fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                    return response.json();
                })
                .then(function (data) {
                    this.props.setLockOwnership(data.owner, data.locked_by, data.locked_at);
                }.bind(this));
        }
    }

    handleClose() {
        // stop updateing the lock
        clearInterval(this.lockUpdater);

        // free the lock
        if ("show_translation_id" in this.props && typeof this.props.show_translation_id !== "undefined") {
            const url = settings.api_server + "/translations/lock/" + this.props.show_translation_id;

            fetch(url, {
                credentials: "include",
                method: "DELETE",
                headers: {
                    "content-type": "application/json"
                }
            })
                .then(function (response) {
                    if (response.status >= 400) {
                        throw new Error("Bad response from server");
                    }
                }
                );
        }
        this.props.unshowTranslation();
        this.props.clearAuctionData();

        // clear the info, so it does not get out of state
        this.carInfoDict = null;
        this.setState({ ...this.default_state });
    }

    /**
     * Combines all alerts and people who requested the translation into a
     * container with fixed height. All parts of the modal need to have a fixed
     * height to allow the column with the translation fields to scroll.
     * @param {React.JSX.Element} lockAlert
     * @param {React.JSX.Element} carAlert
     * @param {React.JSX.Element} completeAlert
     * @returns
     */
    getNotices(lockAlert, carAlert, completeAlert) {
        let requestedBy = [];

        if (this.props.show_translation_id !== undefined
            && this.props.show_translation_id !== -1
            && this.state.loaded)
        {
            requestedBy = this.state.requesters.map(
                r => <Person key={"who"+r.user_id} who={r} />
            );
        }

        // Combine all requesters into a comma-separated list. The CSS rules
        // for the requested-by-list class will ensure it is cut off with
        // ellipses if the list is too long.
        if (requestedBy.length > 0)
            requestedBy = requestedBy.reduce(
                (prev, curr) => [prev, ", ", curr]
            );

        return <Container className="translation-notices">
            <Row>
                {lockAlert && <Col>{lockAlert}</Col>}
                {carAlert && <Col>{carAlert}</Col>}
                {completeAlert && <Col>{completeAlert}</Col>}
            </Row>
            <Row>
                <Col className="requested-by-list">
                    Requested by: {requestedBy}
                    {/* temporarily removed, broken by Aleado
                        this.props.show_translation && this.props.car_data !== null
                        && <RequestQuickImages
                            lot={this.props.car_data.lot}
                            auction_time={this.props.car_data.auction_time}
                            auction_house={this.props.car_data.auction_house}
                        />
                    */}
                </Col>
            </Row>
        </Container>;
    }

    /**
     * Returns a list of controls for editing the translation's main attributes,
     * and another control for creating new attributes.
     */
    mainInfo() {
        const result = [];
        let dict = this.props.main;
        if (dict === null || dict === "") {
            dict = { ...translations_defaultState.main };
        }
        for (const key in dict) {
            result.push(<EditAttribute
                key={key} attribute={key} val={dict[key]}
                handleChange={
                    e => this.props.changeMainAdd(key, e.target.value)
                }
                handleDelete={() => this.props.changeMainDelete(key)}
            />);
        }
        result.push(<CreateAttribute
            key={"Attribute"}
            handleChange={
                (name, val) => this.setState({
                    ...this.state,
                    "newAttr": name,
                    "newVal": val,
                })
            }
            handleAdd={(name, val) => this.props.changeMainAdd(name, val)}
        />);
        return result;
    }

    /**
     * Returns controls for editing all translation data.
     * @param {boolean} readOnly if true, the translation's data can't be edited
     * @returns 
     */
    translationFields(readOnly) {
        return <Container>
            <Row>
                <Col>
                    <Form.Group controlId="formGridEmail">
                        {this.mainInfo()}
                    </Form.Group>
                </Col>
            </Row>
            <Row className="large-translation-fields">
                <Col lg={12} xl={6}>
                    <span>Sales Points</span>
                    <FormControl rows="10" as="textarea"
                        onChange={this.props.changeSalesPoints}
                        value={this.props.sales_points}
                        readOnly={readOnly}
                    />
                </Col>
                <Col lg={12} xl={6}>
                    <span>Notes</span>
                    <FormControl rows="10" as="textarea"
                        onChange={this.props.changeNotes}
                        value={this.props.notes}
                        readOnly={readOnly}
                    />
                </Col>
                <Col lg={12} xl={6}>
                    <span>Report</span>
                    <FormControl rows="10" as="textarea"
                        onChange={this.props.changeReport}
                        value={this.props.report}
                        readOnly={readOnly}
                    />
                </Col>
                <Col lg={12} xl={6}>
                    <span>Diagram Notes</span>
                    <FormControl rows="10" as="textarea"
                        onChange={this.props.changeDiagramNotes}
                        value={this.props.diagram_notes}
                        readOnly={readOnly}
                    />
                </Col>
                <Col lg={12} xl={6}>
                    <span>Exporter Notes</span>
                    <FormControl rows="10" as="textarea"
                        onChange={this.props.changeExporterNotes}
                        value={this.props.exporter_notes}
                        readOnly={readOnly}
                    />
                </Col>
            </Row>
        </Container>;
    }

    /**
     * Returns a collapsable form for ignoring a translation request, and the
     * button to collapse/expand it.
     * @param {boolean} readOnly if true, the translation cannot be edited
     */
    ignoreControls(readOnly) {
        return <div style={{marginTop: "1em"}}>
            <Button disabled={this.state.pushing_data} onClick={this.ignoreViewClick}>Show/hide ignore options</Button>
            <Collapse in={this.state.showIgnore}>
                <Container>
                    <Row>
                        <Col>
                            Why is this translation being ignored?
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormControl onChange={this.changeReason} value={this.state.ignore_reason} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                label="Give translation request count back (requests go up by 1 for all requesters)"
                                checked={this.props.give_request_back}
                                onChange={this.props.toggleGiveBackRequest}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={this.state.pushing_data || readOnly} onClick={this.ignore}>Send translation ignore</Button>
                        </Col>
                    </Row>
                </Container>
            </Collapse>
        </div>;
    }

    render() {
        // don't show if not an admin
        if (!this.props.isAdmin) { return null; }

        let body = <p>Loading</p>;
        let head = null;

        let lockInfo = null;
        let readOnly = false;
        if (this.props.lock_loaded) {
            if (this.props.isLockOwner) {
                lockInfo = <Alert variant="success">Locked Translation</Alert>;
            } else {
                lockInfo = <Alert variant="danger">Locked by {this.props.who} since {this.props.when}</Alert>;
                readOnly = true;
            }
        }

        let completed = null;
        if (this.props.translation_completed) {
            completed = <Alert variant="danger">Translation already completed</Alert>;
        }

        if (this.props.show_translation_id !== undefined
            && this.props.show_translation_id !== -1
            && this.state.loaded)
        {
            body = <Container className="translation-fields">
                <Row>
                    <Col md={6}>
                        <AuctionCarImages separateSheet={false} size={12}/>
                    </Col>
                    <Col md={6}>
                        {this.translationFields(readOnly)}
                        <Container style={{marginBottom: "1em"}}>
                            <span>Chassis Code Lookup</span>
                            <ProductionData data={this.props.car_data}/>
                        </Container>
                        <RequestedImageUploadByID
                            image_request_id={this.props.show_vehicle_id}
                        />
                        <ImageList vehicle_id={this.props.show_vehicle_id}
                            user_id={-1}
                        />
                        {this.ignoreControls(readOnly)}
                    </Col>
                </Row>
            </Container>;
        }
        let carAlert = null;
        // Car might not have any associated data (Removed from auction?) Check values to prevent crash
        if (!this.props.car_data_loading && this.props.car_data && this.props.car_data.auction_house && this.props.car_data.lot) {
            head = <Modal.Title>Create Translation: {this.props.car_data.auction_house} Lot: {this.props.car_data.lot} At: {this.props.car_data.auction_time}</Modal.Title>;
        }

        // If it's loaded, but no data came back from the API
        else if (!this.props.car_data_loading) {
            // short message so it can be a single line
            carAlert = <Alert variant="danger">Failed to fetch car data</Alert>;
        }

        return <Modal
            size="lg"
            show={this.props.show_translation}
            onHide={this.save}
            onEntering={this.loadData}
            dialogClassName="translation-dialog"
        >
            <Modal.Header closeButton>
                {head}
            </Modal.Header>
            <Modal.Body className="translation-modal-body">
                <AdminOnly />
                {this.getNotices(lockInfo, carAlert, completed)}
                {body}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={this.state.pushing_data || readOnly} onClick={this.saveAndClose}>Complete and notify</Button> <Button disabled={this.state.pushing_data || readOnly} onClick={this.save}>Save</Button> <Button disabled={this.state.pushing_data} onClick={this.handleClose}>Discard changes</Button>
            </Modal.Footer>
        </Modal>;
    }
}

const mapStateToProps = state => {
    return {
        show_translation: state.translations.show_translation,
        show_translation_id: state.translations.show_translation_id,
        show_vehicle_id: state.translations.show_translation_vehicle_id,

        main: state.translations.main,
        report: state.translations.report,
        notes: state.translations.notes,
        sales_points: state.translations.sales_points,
        diagram_notes: state.translations.diagram_notes,
        exporter_notes: state.translations.exporter_notes,
        give_request_back: state.translations.give_request_back,
        translation_completed: state.translations.translation_completed,

        car_data: state.auction_car.data,
        car_data_loading: state.auction_car.loading,
        failure: state.auction_car.failure,

        isAdmin: state.profile.is_admin,

        lock_loaded: state.translations.lock_loaded,
        isLockOwner: state.translations.isLockOwner,
        who: state.translations.who,
        when: state.translations.when
    };
};

const mapDispatchToProps = dispatch => ({
    unshowTranslation: () => dispatch(unshowTranslation()),
    clearAuctionData: () => dispatch(clearAuctionData()),
    changeMainRaw: (t) => dispatch(changeMain(t)),
    changeMainAdd: (key, val) => dispatch(changeMainAdd(key, val)),
    changeMainDelete: (key) => dispatch(changeMainDelete(key)),
    changeMain: (t) => dispatch(changeMain(t)),
    changeSalesPoints: (t) => dispatch(changeSalesPoints(t.target.value)),
    changeNotes: (t) => dispatch(changeNotes(t.target.value)),
    changeReport: (t) => dispatch(changeReport(t.target.value)),
    changeDiagramNotes: (t) => dispatch(changeDiagramNotes(t.target.value)),
    changeExporterNotes: (t) => dispatch(changeExporterNotes(t.target.value)),
    setAllTranslationData: (main, desc, sales, notes, report, diagram, exporter, done) => dispatch(setAllTranslationData(main, desc, sales, notes, report, diagram, exporter, done)),
    toggleGiveBackRequest: () => dispatch(toggleGiveBackRequest()),
    setLockOwnership: (isOwner, who, when) => dispatch(setLockOwnership(isOwner, who, when))
});

export default connect(mapStateToProps, mapDispatchToProps)(TranslationModal);
