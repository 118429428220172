import React, { Component } from "react";
import "../../App.css";
import { connect } from "react-redux";

import {
    Button,
} from "react-bootstrap";

import { toggleAskModal } from "../../actions/questions_actions";


class Question extends Component {

    constructor(){
        super();
        this.canAskQuestions = this.canAskQuestions.bind(this);
    }

    canAskQuestions() {
        return this.props.can_bid ||
            this.props.can_request_translation ||
            this.props.is_admin ||
            this.props.isvip;
    }

    render() {
        // Only show a button if the user can ask questions.
        if (this.canAskQuestions()) {
            // Disable the button if the auction car data is still loading, or
            // we failed to fetch it.
            return <p>
                <Button block onClick={this.props.toggleAskModal}
                    disabled={this.props.loading || this.props.failure}
                >
                    Ask a question
                </Button>
            </p>;
        }

        return <p>Questions available upon deposit</p>;

    }
}
const questionState = state => {
    return {
        can_bid: state.profile.can_bid,
        can_request_translation: state.profile.can_request_translation,
        is_admin: state.profile.is_admin,
        isvip: state.profile.isvip,
        loading: state.auction_car.loading,
        failure: state.auction_car.failure,
    };
};

const questionDispatch = dispatch => ({
    toggleAskModal: () => dispatch(toggleAskModal()), // for opening the modal
});

export default connect(questionState, questionDispatch)(Question);
