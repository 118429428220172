import { settings } from "../settings";
import React from "react";
import {
    Button,
} from "react-bootstrap";

/**
 * This component shows an admin which quiz is required to be completed for a
 * purchase. It has buttons which allow admins to bypass the quiz, or remind the
 * customer of it. **This component assumes there is a quiz assigned to the
 * purchase. This component shouldn't be shown at all if there isn't a quiz.**
 * @param {Object} props
 * @param {string} props.purchase_id
 * @param {Object} props.quiz
 * @param {() => void} props.updateCallback
 * @returns 
 */
export default function CarPendingQuizAdmin({
    purchase_id,
    quiz,
    updateCallback,
})
{
    // set to true if admin clicks "Remind" button, which disables the button to
    // prevent accidental repeated reminders
    const [reminded, setReminded] = React.useState(false);

    // when we load a new purchase or assign a new quiz, clear the reminded flag
    React.useEffect(() => {
        setReminded(false);
    }, [purchase_id, quiz]);

    // bypass the quiz
    const bypassQuiz = React.useCallback(() => {
        const url = settings.api_server + "/quiz/admin/" + purchase_id;
        fetch(url, {
            credentials: "include",
            method: "DELETE"
        })
            .then(resp => resp.ok ?
                Promise.resolve()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .then(
                updateCallback,
                reason => {
                    console.log("Failed to bypass quiz: " + reason);
                }
            );
    }, [purchase_id, updateCallback]);

    // remind the user of the quiz
    const remindQuiz = React.useCallback(() => {
        // Immediately set to true so the notifications can't be spammed.
        // We'll set it to false again if the reminder fails.
        setReminded(true);
        const url = settings.api_server + "/quiz/admin/" + purchase_id + "/remind";
        fetch(url, {
            credentials: "include",
            method: "POST"
        })
            .then(resp => resp.ok ?
                Promise.resolve()
                : Promise.reject(resp.status + " " + resp.statusText)
            )
            .catch(
                reason => {
                    console.log("Failed to remind user of quiz: " + reason);
                    setReminded(false);
                }
            );
    }, [purchase_id]);

    return <>
        {quiz.quiz_name} &nbsp;
        <Button size="sm" variant="outline-warning" onClick={bypassQuiz}>
            Remove
        </Button> &nbsp;
        <Button size="sm" variant="outline-info" onClick={remindQuiz}
            disabled={reminded}
        >
            {reminded ? "Reminder sent" : "Remind"}
        </Button>
    </>;
}
