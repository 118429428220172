import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
    Alert,
    FormControl,
    Modal,
    Button,
    FormGroup, FormLabel
} from "react-bootstrap";

import { toggleAskModal, add_question } from "../actions/questions_actions";

// passed auction_id as a prop
class AskQuestionModal extends Component {
    constructor () {
        super();
        this.state = { text: "" };
        this.saveClick = this.saveClick.bind(this);
        this.changeText = this.changeText.bind(this);
    }

    saveClick () {
        // It shouldn't be possible to open this modal if we can't load the
        // auction data. But we'll be extra sure that we have the auction data
        // just in case.
        if (this.props.car_data) {
            this.setState({ ...this.state, pushing_data: true });
            const title = this.props.car_data.auction_house + " " + this.props.car_data.make + " " + this.props.car_data.model;
            add_question(this.props.auction_id, this.state.text, title);
        }
        this.props.toggleAskModal();
        this.setState({ text: "" });
    }

    changeText (e) {
        this.setState({ ...this.state, text: e.target.value });
    }

    render () {
        return (
            <Modal size='lg' show={this.props.showModal} onHide={this.props.toggleAskModal}>
                <Modal.Header>Ask a question</Modal.Header>
                <Modal.Body>
                    {this.props.message !== null && <Alert>{this.props.message}</Alert>}
                    <FormGroup controlId="question">
                        <FormLabel>Ask a question about this car, or the translation:</FormLabel>
                        <FormControl rows="5" as="textarea" onChange={this.changeText} value={this.state.text} />
                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.saveClick}>Ask</Button>
                    <Button onClick={this.props.toggleAskModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

AskQuestionModal.propTypes = {
    auction_id: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        showModal: state.questions.showAskModal,
        car_data: state.auction_car.data
    };
};

const mapDispatchToProps = dispatch => ({
    toggleAskModal: () => dispatch(toggleAskModal()) // for closing the modal
});

export default connect(mapStateToProps, mapDispatchToProps)(AskQuestionModal);
