
import { settings } from "../settings";

const alert = (state = settings.auction_car_defaultState, action) => {
    switch (action.type) {
    case "AUCTION_CAR_FETCHING":
        return {
            ...state,
            loading: true,
            failure: false
        };
    case "SET_AUCTION_CAR_DATA":
        return {
            ...state,
            data: action.data,
            loading: false,
            id: action.data.vehicle_id
        };
    case "AUCTION_CAR_DATA_NOT_FOUND":
        return {
            ...state,
            data: settings.auction_car_defaultState.data,
            loading: false,
            failure: true,
            // still store an ID so we can fetch other data such as translations
            // and requested images
            id: action.auction_id
        };
    case "CLEAR_AUCTION_CAR_DATA":
        return {
            ...settings.auction_car_defaultState
        };
    case "BID_LOADED":
        return {
            ...state,
            has_bid: action.has_bid,
            bid_amount: action.amount,
            bid_loaded: true,
            is_cancelled: action.isCancelled,
            carIsBiddable: action.canBid,
            bidMessage: action.bidMessage,
            comment: action.comment
        };
    case "WATCH_LOADED":
        return {
            ...state,
            watch_loaded: true,
            isWatched: action.isWatched
        };
    case "TRANSLATION_LOADED":
        return {
            ...state,
            requested_translation: action.requested_translation,
            translation_completed: action.translation_completed,
            translation: action.translation,
            translation_loaded: true
        };

    default:
        return state;
    }
};

export default alert;
