import React, { Component } from "react";

import {
    Table,
    Col, Row,
    Button
} from "react-bootstrap";
// better way to import
import Container from "react-bootstrap/Container";

import { connect } from "react-redux";

import {
    setSortColumn,
    doSearch,
    clear_results,
    clear_pages,
    clearSelection,
    toggleViewSearch
} from "../actions/search_actions";

import LoadingSpinner from "../widgets/LoadingSpinner";
import LoadingError from "../widgets/LoadingError";

import Octicon, { TriangleDown, TriangleUp } from "@githubprimer/octicons-react";
import ResultsRow from "./result_views/result_row";
import Pager from "./result_views/pager";
import CommaNumbers from "../widgets/commaNumbers";

class AvtoResults extends Component {
    constructor() {
        super();

        this.sortClick = this.sortClick.bind(this);
        this.sortByRate = this.sortByRate.bind(this);

        this.yearClick = this.yearClick.bind(this);
        this.makeClick = this.makeClick.bind(this);
        this.gradeClick = this.gradeClick.bind(this);
        this.odoClick = this.odoClick.bind(this);
        this.auctionClick = this.auctionClick.bind(this);
        this.chassisClick = this.chassisClick.bind(this);
        this.displacementClick = this.displacementClick.bind(this);
        this.modelClick = this.modelClick.bind(this);
    }

    // to clear the results if the user moves off this page
    componentWillUnmount() {
        this.props.clear_results();
        this.props.clearSelection();
    }

    sortByRate() {

    }

    sortClick(id) {
        // sort is by id. -'ve ids for reverse sort
        const newId = parseInt(id, 10);
        let sortColumn = 0;
        if (newId === Math.abs(this.props.sortColumn)) {
            // sort reverse
            sortColumn = -1 * this.props.sortColumn;
        }
        else {
            sortColumn = newId;
        }
        this.props.setSortColumn(sortColumn);
        // clear the results in each page so they get re-fetched in the correct
        // order, but leave the count, number of pages, etc.
        this.props.clear_pages();
    }

    yearClick() { this.sortClick(1); }
    modelClick() { this.sortClick(2); }
    gradeClick() { this.sortClick(3); }
    auctionClick() { this.sortClick(4); }
    odoClick() { this.sortClick(5); }
    displacementClick() { this.sortClick(6); }
    chassisClick() { this.sortClick(7); }
    makeClick() { this.sortClick(8); }

    // When we switch to a new page, fetch it.
    componentDidUpdate(prevProps) {
        if (prevProps.page !== this.props.page) {
            // If we've already fetched the page, this function does nothing.
            doSearch(this.props.data);
        }
    }

    render() {
        // not yet ready to show results
        if (!this.props.showResults) {
            return null;
        }

        if (this.props.errorText !== null) {
            return <LoadingError message={this.props.errorText}/>;
        }

        // still fetching the results
        if (this.props.fetching || !(this.props.page in this.props.pages)) {
            return <LoadingSpinner message="Fetching results..."/>;
        }

        const data = this.props.pages[this.props.page];

        const rows = data.map(result => <ResultsRow key={"results" + result.vehicle_id} car={result} />);

        // If no results, show message informing user and let them refine/reset
        // their search.
        if (this.props.page === 0 && data.length === 0) {
            return (
                <Container className="d-flex justify-content-center">
                    <Row>
                        <Col className="text-center align-bottom">
                            <span className="smallWhiteTitle"><br/>No results. Try widening your search.</span>
                            <div style={{textAlign: "right", marginTop: "10px"}}>
                                <Button onClick={this.props.clearSelection} style={{float: "left"}}>
                                    New Search
                                </Button>
                                &nbsp;
                                <Button onClick={this.props.toggleViewSearch} style={{float: "right"}}>
                                    Refine Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            );
        }
        // container is a bootstrap class
        return (
            <div>
                <Container className="wideContainer">
                    <div className="whiteTitle">Results</div>
                    <Row>
                        <Col></Col>
                        <Col className="align-bottom text-center"><Pager /></Col>
                        <Col className="text-right align-bottom">
                            <span className="smallWhiteTitle">Total results  {CommaNumbers(this.props.count)}</span>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={this.props.clearSelection}>
                                    New Search
                                </Button>
                                &nbsp;
                                <Button onClick={this.props.toggleViewSearch}>
                                    Refine Search
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="whiteBackground dropShadow wideContainer">


                    <Table striped bordered size="sm" hover>
                        <thead>
                            <tr>
                                <th>Auction Info</th>
                                <th className="imageColumn">Image</th>
                                <th id={1} >
                                    <span className="theHand" onClick={this.yearClick}>Year</span>{this.props.sortColumn === 1 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -1 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 1 && "↕"}
                                </th>
                                <th id={7}>
                                    <span className="theHand" onClick={this.chassisClick}>Chassis</span>{this.props.sortColumn === 7 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -7 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 7 && "↕"}
                                </th>
                                <th id={8}>
                                    <span className="theHand" onClick={this.makeClick}>Make</span> {this.props.sortColumn === 8 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -8 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 8 && "↕"}
                                </th>
                                <th id={2}>
                                    <span className="theHand" onClick={this.modelClick}>Model</span> {this.props.sortColumn === 2 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -2 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 2 && "↕"}
                                </th>
                                <th>Submodel</th>
                                <th>Transmission</th>
                                <th id={6}>
                                    <span className="theHand" onClick={this.displacementClick}>Engine</span>{this.props.sortColumn === 6 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -6 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 6 && "↕"}
                                </th>
                                <th id={3}>
                                    <span className="theHand" onClick={this.gradeClick}>Grade</span> {this.props.sortColumn === 3 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -3 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 3 && "↕"}
                                </th>
                                <th id={5}>
                                    <span className="theHand" onClick={this.odoClick}>Odometer</span> {this.props.sortColumn === 5 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -5 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 5 && "↕"}
                                </th>
                                <th id={4}>
                                    <span className="theHand" onClick={this.auctionClick}>Auction Time</span> {this.props.sortColumn === 4 && <Octicon icon={TriangleDown} />} {this.props.sortColumn === -4 && <Octicon icon={TriangleUp} />} {Math.abs(this.props.sortColumn) !== 4 && "↕"}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </Table>
                    <Container className="d-flex justify-content-center" >
                        <span>
                            <Pager />
                        </span>
                    </Container>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        sortColumn: state.search.sortColumn,
        page: state.search.page,
        data: state.search, // for requerying when changing the page or sort
        fetching: state.search.fetching,

        showResults: state.search_results.showResults,
        count: state.search_results.count,
        pages: state.search_results, // pages are indexed in a dict, numerically
        errorText: state.search_results.errorText
    };
};

const mapDispatchToProps = dispatch => ({
    setSortColumn: (col) => dispatch(setSortColumn(col)),
    clear_results: () => dispatch(clear_results()),
    clear_pages: () => dispatch(clear_pages()),
    clearSelection: () => dispatch(clearSelection()),
    toggleViewSearch: () => dispatch(toggleViewSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(AvtoResults);
