import React from "react";

import {
    Form,
    Button,
    Row,
    Col,
} from "react-bootstrap";

import "./translation_modal.css";

/**
 * Returns a FormGroup rendered as a Row, for editing or deleting a translation
 * attribute.
 * @param {Object} props
 * @param {string} props.attribute the name of the attribute to edit/delete
 * (displayed as a label next to the text input)
 * @param {string} props.val the value of the attribute (placed in a text input)
 * @param {(e: Event) => void} props.handleChange callback when `val` is changed
 * @param {(e: Event) => void} props.handleDelete callback when the delete
 * button is clicked
 */
export default function EditAttribute({
    attribute,
    val,
    handleChange,
    handleDelete,
})
{
    return <Form.Group className="attribute-row" as={Row}>
        <Form.Label column sm={3}>
            {attribute}
        </Form.Label>
        <Col sm={8} size="sm">
            <Form.Control type="textarea"
                value={val}
                onChange={handleChange}
            />
        </Col>
        <Col sm={1}>
            <Button variant="danger" size="sm" onClick={handleDelete}>
                -
            </Button>
        </Col>
    </Form.Group>;
}
