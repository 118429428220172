import React from "react";
import {
    Container,
    Row,
    Col,
    ListGroup,
    Card,
    InputGroup,
    FormControl,
    Button,
} from "react-bootstrap";
import moment from "moment";

import { settings } from "../settings";

import PCANav from "../dashboard-components/navbar";
import AdminOnly from "../dashboard-components/AdminOnly";
import LoadingError from "../widgets/LoadingError";
import LoadingSpinner from "../widgets/LoadingSpinner";
import TranslationLookupItem from "../admin_views/translation_lookup/TranslationLookupItem";
import TranslationModal from "../admin_views/translation_modal/translation_modal";
import UserLookup from "../admin_views/UserLookupTool";
import DateRangePicker from "../widgets/DateRangePicker";

/**
 * An admin-only page for viewing all translations, and filtering based on date,
 * who requested it, and text content.
 */
export default function ViewAllTranslations()
{
    const [translations, setTranslations] = React.useState(null);
    const [error, setError] = React.useState(null);
    // The text currently in the search bar.
    const [searchValue, setSearchValue] = React.useState("");
    // The text that was searched for to get the currently-displayed results,
    // needs to be separate from the live search bar value because it's used by
    // TranslationLookupItem.
    const [searchedText, setSearchedText] = React.useState("");
    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    const [lookupUserID, setLookupUserID] = React.useState(null);

    // Fetch translations using the /translations/search endpoint.
    const fetchData = React.useCallback((searchText) => {
        const encoded = window.btoa(searchText);
        const url = `${settings.api_server}/translations/search?query=${encoded}`;
        fetch(url, {credentials: "include"})
            .then(resp => resp.ok ?
                resp.json()
                : Promise.reject(resp.status + " " + resp.statusText))
            .then(data => data.success ?
                data.results
                : Promise.reject(data.message))
            .then(
                results => {
                    setTranslations(results);
                    setError(null);
                },
                reason => {
                    setTranslations(null);
                    setError(reason);
                }
            );
    }, []);

    // When the user clicks 'Search' or hits Enter, search using their input.
    const doSearch = React.useCallback((textInput) => {
        setSearchedText(textInput);
        fetchData(textInput);
    }, [fetchData]);

    // On mount, fetch all translations.
    React.useEffect(() => doSearch(""), []);

    // Callbacks for date range picker
    const onStartDate = React.useCallback((start) => {
        // don't accept strings, only valid Moments
        if (!start || typeof(start) === "string")
            setStartDate(null);
        else
            setStartDate(start.tz("Asia/Tokyo").startOf("day"));
    }, []);
    const onEndDate = React.useCallback((end) => {
        // don't accept strings, only valid Moments
        if (!end || typeof(end) === "string")
            setEndDate(null);
        else
            setEndDate(end.tz("Asia/Tokyo").endOf("day"));
    }, []);

    let content = null;
    if (translations === null)
    {
        if (error)
        {
            content = <LoadingError
                message={"Failed to fetch translations: " + error}
            />;
        }
        else
        {
            content = <LoadingSpinner/>;
        }
    }
    else
    {
        const filteredResults = Object.values(translations)
            // parse auction date to compare with startDate/endDate
            .map(t => ({
                ...t,
                auction_date_moment: moment.tz(
                    t.translation.auction_date, "Asia/Tokyo"
                ),
            }))
            // filter to the selected user
            .filter(t => (!lookupUserID || t.requesters.includes(lookupUserID)))
            // filter to selected date range
            .filter(t => (!startDate || t.auction_date_moment >= startDate))
            .filter(t => (!endDate || t.auction_date_moment <= endDate));
        content = <ListGroup variant="flush">
            {filteredResults.map(r => <TranslationLookupItem
                searchText={searchedText}
                translation={r}
                extended
                key={r.translation.translation_id}
            />)}
        </ListGroup>;
    }

    return <div>
        <AdminOnly/>
        <PCANav/>
        <Container className="wideContainer">
            <Container>
                <h2 className="whiteTitle">All Translations</h2>
            </Container>
            <Container className="whiteBackground dropShadow" style={{
                paddingTop: "15px"
            }}>
                <Row style={{marginBottom: "15px"}}>
                    <Col md={8}>
                        <Row>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        Search contents:
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    autoComplete="off"
                                    placeholder="Enter translation text"
                                    value={searchValue}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter")
                                            doSearch(searchValue);
                                    }}
                                />
                                <InputGroup.Append>
                                    <Button
                                        size="sm"
                                        variant="outline-success"
                                        onClick={() => doSearch(searchValue)}
                                    >
                                        Search
                                    </Button>
                                    <Button
                                        size="sm"
                                        variant="outline-danger"
                                        onClick={() => {
                                            setSearchValue("");
                                            doSearch("");
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Row>
                        <DateRangePicker
                            onStartChanged={onStartDate}
                            onEndChanged={onEndDate}
                            startValue={startDate}
                            endValue={endDate}
                            startPlaceholder="Start auction date"
                            endPlaceholder="End auction date"
                            dateFormat="YYYY-MM-DD"
                            timeFormat={false}
                            clearButtonVariant="outline-primary"
                            style={{
                                paddingLeft: "7px",
                                paddingRight: "7px",
                                marginTop: "5px",
                            }}
                        />
                    </Col>
                    <Col md={4}>
                        Requested by:
                        {/* Callback is supposed to take an ID and name but we
                            only need an ID. Include a clear button so we have
                            a way to undo filtering by user. */}
                        <UserLookup
                            chosenCallback={setLookupUserID}
                            clearButton
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card className="cardSpace">
                            <Card.Header style={{
                                textAlign: "center",
                                fontSize: "1.25em",
                            }}>
                                <b>Translations</b>
                            </Card.Header>
                            <Card.Body style={{padding: "0px"}}>
                                {content}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
        <TranslationModal/>
    </div>;
}
