import Pagination from "react-bootstrap/Pagination";
import React, { Component } from "react";
import { connect } from "react-redux";

import "./pager.css";

import {
    nextPage,
    prevPage
} from "../../actions/search_actions";

const items_per_page = 50; // must match back-end.


class ResultPager extends Component {

    render() {
        return <div>
            <div style={{textAlign: "center"}}>PAGE</div>
            <Pagination className="d-flex justify-content-center">
                <Pagination.Prev   onClick={this.props.prevPage} disabled={this.props.page === 0}>
                    ◀ 
                </Pagination.Prev>
                <span className="pager-middle">{this.props.page + 1} of {Math.ceil(this.props.count / items_per_page)}</span>
                <Pagination.Next   onClick={this.props.nextPage} disabled={(this.props.page + 1) * items_per_page > this.props.count}>
                    ▶
                </Pagination.Next>
            </Pagination>
        </div>;
    }
}


const mapStateToProps = state => {
    return {
        page: state.search.page,
        count: state.search_results.count,
    };
};

const mapDispatchToProps = dispatch => ({
    nextPage: () => dispatch(nextPage()),
    prevPage: () => dispatch(prevPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultPager);